var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-form',{staticClass:"q-gutter-md",on:{"submit":_vm.onSubmit}},[_c('q-input',{ref:"email",attrs:{"dense":"","outlined":"","type":"email","no-error-icon":"","placeholder":"E-mail do Reuni","autocorrect":"off","autocapitalize":"off","autocomplete":"off","spellcheck":"false","hide-bottom-space":"","rules":[
      () => _vm.$v.credentials.email.required || 'E-mail é obrigatório',
      () => _vm.$v.credentials.email.email || 'E-mail inválido'
    ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"mdi-account","size":"xs"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.credentials.email),expression:"credentials.email"}],staticClass:"cursor-pointer",attrs:{"size":"xs","name":"mdi-close"},on:{"click":function($event){_vm.credentials.email = ''}}})]},proxy:true}]),model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}}),_c('q-input',{ref:"password",attrs:{"dense":"","outlined":"","no-error-icon":"","placeholder":"Senha","type":_vm.isPassword ? 'password' : 'text',"rules":[
      () => _vm.$v.credentials.password.required || 'Senha é obrigatória',
      () => _vm.$v.credentials.password.minLength || 'Senha inválida'
    ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"mdi-shield-lock-outline","size":"xs"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"size":"xs","name":_vm.isPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click":function($event){_vm.isPassword = !_vm.isPassword}}})]},proxy:true}]),model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}}),_c('div',{staticClass:"row items-center justify-between"},[_c('q-btn',{attrs:{"flat":"","dense":"","no-caps":"","padding":"0","ripple":false,"color":"primary","label":"Esqueceu a senha?","to":_vm.authForgotRoute}}),_c('q-btn',{attrs:{"flat":"","dense":"","no-caps":"","padding":"0","ripple":false,"color":"primary","label":"Primeiro Acesso?","to":_vm.authSignUp}}),_c('AuthSubmit',{attrs:{"label":"Entrar","loading":_vm.auth.loading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }